
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {app} from "../firebase/firebase";

export const SignIn= async(email,password)=>{
    console.log("Admin",email,password);
const auth = await getAuth(app);
  await signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    console.log("User",user);
    if(user)
    {
        localStorage.setItem("user",JSON.stringify(user))
        localStorage.setItem("token", user.accessToken)
    }
    return true;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
}

export const verifyToken =async(idToken)=>{
    const auth =  await getAuth(app)
  .verifyIdToken(idToken)
  .then((decodedToken) => {
    const uid = decodedToken.uid;
    return uid;
    // ...
  })
  .catch((error) => {
    // Handle error
  });
}
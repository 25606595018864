import React  from "react";
import { Card, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import CounterInput from "react-bootstrap-counter";
import { useState } from "react";

const Product = (props) => {
  console.log("THESE ARE THE PROPS",props);
  const {description,epic1,epic2,epic3,mainPic,model,price,quantity,sku,subType,title,type} =props?.productsDetails;
  const [squantity,setQuantity]=useState(1);
  
  const modifyProduct=()=>{
    const nproduct ={ ...props?.productsDetails,sQuanity :squantity}
    props?.addProduct(nproduct);

  }

  const images = [
    { url: "https://media.istockphoto.com/photos/network-cables-connected-to-switch-picture-id505921926?b=1&k=20&m=505921926&s=170667a&w=0&h=5_ov95klaDSxeSdAWxZXi3lcR2u65TdYZjI8eZARSBE=" },
    { url: "https://wallpaperaccess.com/full/302537.jpg" },
    { url: "https://wallpapercave.com/wp/wp5599755.jpg" },
  ];
  return (

    <div >
      <Card style={{ width: '26rem', background: "White", textAlign: "center", color: "black", border: "1px solid black" , minHeight:"460px" }}>
        <Card.Img style={{ width: '17rem' ,padding:"2px" }} variant="top" src={mainPic} />
        <Card.Body>
          <div style={{height:"90px",maxHeight:"100px"}}>
          <Card.Title>{title} </Card.Title>
          </div>
          <Card.Text >
            <div>SKU : {sku}</div>
            <div>Model : {model}</div>
            {/* <div style={{overflow:"hidden"}}>{description?description : "Not found"}</div> */}
             
          </Card.Text>
          <h6>Price: <span color="blue">USD {price}</span></h6>
          <CounterInput max={10} min={1} onChange={(value) => { setQuantity(value) }} value={1} />
          {/* <Button style={{ margin: "10px", background: "#333" }} variant="primary" onClick={props?.addProduct(props.productDetails)}>Add to Cart</Button> */}
         <Button style={{ margin: "10px", background: "#333" }} onClick={()=>{console.log("ADDTHIS");modifyProduct()}} variant="primary">Add to Cart</Button>
       
        </Card.Body>
      </Card>
    </div>
  )
}
export default Product
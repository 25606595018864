import React from "react";
import { useState, useEffect } from "react";
import Slider from "../../components/Slider";
import { Headingbar } from "../../components/Headingbar";
import Product from "../../components/product";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import {checkout,PlaceOrder}from "../../utils/services/products";
import Button from '@mui/material/Button';
import { InfoBar } from "../../components/infobar";
import ShoppingCart from "../../components/ShoppingCart";
import { useNavigate } from "react-router-dom";
import emailjs from 'emailjs-com';
import Container from 'react-bootstrap/Container';

const Thankyou = () => {
  const page ='Checkout'
  const [landingPageData, setLandingPageData] = useState({});
  const [orderID,setOrderID]=useState();
  const [cartOpen,setCartOpen]=useState(false);

  const navigate = useNavigate();
  const [selectedProducts,setselectedProducts]=useState([]);



  useEffect(async () => {
    setLandingPageData(JsonData);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlUuid = urlParams.get('id')
    const props = JSON.parse(localStorage.getItem('OrderDetails'));


if(props && props.uuid ==urlUuid){
console.log("props",props);
 await PlaceOrder(props).then(id =>{
  setOrderID(id);
  var templateParams = {
    name: props.fullName,
    orderId: id
};
  emailjs.init(process.env.REACT_APP_PUBLIC_KEY);
  emailjs
  .send(
    process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_ORDER_TEMP,templateParams, 
  )
  .then(
    (result) => {
      console.log("hello", result.text)
      localStorage.clear();
    },
    (error) => {
      console.log("hellox", error.text)
    }
  )

  
 });
}
else{
  localStorage.clear();
  navigate('/products')
}

  }, []);

  const handleClose =()=>{

    setCartOpen(false);
  }
  const openCart =()=>{
    console.log("OPEN THE CARt");
    setCartOpen(true);
  }

  return (
    <>
      <Headingbar selectedProducts={selectedProducts} setCartOpen={openCart}/>
      <ShoppingCart show={cartOpen}handleClose={handleClose} selectedProducts={selectedProducts}/>
      
      <div >
        <Slider />
      </div>
      <InfoBar />

      <Container style={{marginTop:'100px',marginBottom:'100px'}}>
      <h1 class="site-header__title" data-lead-id="site-header-title" style={{color:"black",textAlign:"center"}}>THANK YOU!</h1>
      <div class="main-content" style={{fontSize:"200px",color:"seagreen",textAlign:"center"}}>
		<i class="fa fa-check main-content__checkmark" id="checkmark" ></i>
		<p class="main-content__body" data-lead-id="main-content-body" style={{color:"black",textAlign:"center"}}>Thanks a bunch for buying from us. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you. We have also sent your order details on you email, In case of any query you can contact us on the numbers given below.</p>
	</div>
    </Container>



      <Contact data={landingPageData.Contact} />
    </>
  )
}
export default Thankyou;
import { useState, useEffect } from "react";
import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Features } from "../../components/features";
import { About } from "../../components/about";
import { Services } from "../../components/services";


import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });

 const OverView=()=>{
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    return(
        <div>
        <Navigation />
        <Header data={landingPageData.Header} />
        <Services data={landingPageData.Services} />
        <Features data={landingPageData.Features} />
        <About data={landingPageData.About} />
        <div style={{height:"30px",background:"#333"}}></div>
        <Contact data={landingPageData.Contact} />
        </div>
    )
}
export default OverView
import Final from "../assests/videos/Final.mp4"
import Final2 from "../assests/videos/Final2.ogg"

export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay' style={{overflow:"hidden"}}>
        <video
          muted
          autoPlay={true}
          loop
          // poster={poster}
          style={{position:"fixed", overflow:"hidden" ,minHeight: "100vh" ,zIndex :"-1"}}
          className={"videoclass"}
          >
            <source  src={Final} type="video/mp4"/>
            <source  src={Final2} type="video/ogg"/>
            
          </video>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
              
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='/learnmore'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn More
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

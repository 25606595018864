import { fontSize, textAlign } from "@mui/system";

 const We = () => {
  console.log("we are here");
  return (
    <div style={{padding:"26px"}}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <div className="about-text">
              <h2 style={{color:"white",marginTop:"10px",textAlign:"left"}}>Who are We ?</h2>
              <h6 style={{fontFamily:"TimesNewRoman" ,textAlign:"left" ,fontSize:"25px"}} class="animate__animated animate__slideInRight">Net wave Global manage all aspects of global information technology services covering multi-vendor 
              Net wave Global Prover’s IT Services with an impressive track record of delivering effective solutions to clients. By offering a comprehensive range of professional, technical & support specialist. We are a trusted teaming partner to industry leaders, supplying professional white label services using our local engineering teams
          </h6>           
          </div>
                    
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 style={{color:"white",marginTop:"10px",textAlign:'left'}}>Why choose Us ?</h2>
              <h6  style={{ fontFamily:"TimesNewRoman" ,textAlign:"left" ,fontSize:"25px" ,maxWidth:"560px"}}class="animate__animated animate__slideInRight">      
           We have the most cost-effective solutions.
           <br/>
           <br/>
           Delivering complete technology services supplying 24/7 global maintenance, installation, infrastructure services and resourcing over the Glob. We have strong hands in middle east.
           <br/>
           <br/>
           Everything is Possible 
           <br/>


          </h6>
                 
     </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default We;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {AddProducts} from "./AddProducts";

export const DashBoard = () => {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        getAuth();
    }, [])
    useEffect(() => {
        console.log("isloading", isLoading);
        if (!isLoading) {
            navigate('/admin');
        }

    }, [isLoading])
    const getAuth = () => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        console.log(token);
        if (token) {
            setIsLoading(true)
            return
        }
         setIsLoading(false)
    }
    return (

        <div color="black">
            <h2 style={{ color: "black" }}> Hello ! Admin Welcome to your DashBoard</h2>
            <h3 style={{ color: "black" }}> Add products</h3>
            <AddProducts/>
        </div>

    )
}


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button, Form } from "react-bootstrap"
import { uploadfile, AddProduct } from "../../utils/services/products";
import { async } from "@firebase/util";
interface Product {
  type: string,
  createdAt: Date,
  description: string,
  model: string,
  quantity: number,
  sku: string,
  title: string,
  mainPic: string,
  epic1?: string,
  epic2?: string,
  epic3?: string,
  price: number,
  subCategory:string,
  category:string,
};

export const AddProducts = () => {
  const [mainPic, setMainPic] = useState<string>('');
  const [ePic1, setEPic1] = useState<string>('');
  const [ePic2, setEPic2] = useState<string>('');
  const [ePic3, setEPic3] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [subCategory, setSubCategory] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [sku, setSku] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [price, setPrice] = useState<number>(0);

  const getFormValues = async (event: any) => {
    event.preventDefault();
    console.log(event.target.name, event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "type":
        setType(value);
        break;
        case "subCategory":
        setSubCategory(value);
        break;
        case "category":
          setCategory(value);
          break;
      case "model":
        setModel(value);
        break;
      case "price":
        setPrice(value);
        break;
      case "sku":
        setSku(value)
        break;
      case "description":
        setDescription(value);
        break;
      case "title":
        setTitle(value);
        break;
      case "quantity":
        setQuantity(value);
        break;
      case "picture":
        console.log(event.target.value, "hi", event.target.files[0]);
        await uploadfile(event.target.files[0],event.target.files[0].name).then((value: any) => {
          console.log("pic",value);
          setMainPic(value);

        });
        break;
      case "epic1":
        console.log(event.target.value, "hi", event.target.files[0]);
        await uploadfile(event.target.files[0]).then((value: any) => {
          console.log(value);
          setEPic1(value);

        });
        break;
      case "epic2":
        console.log(event.target.value, "hi", event.target.files[0]);
        await uploadfile(event.target.files[0]).then((value: any) => {
          console.log(value);
          setEPic2(value);

        });
        break;
      case "epic3":
        console.log(event.target.value, "hi", event.target.files[0]);
        await uploadfile(event.target.files[0]).then((value: any) => {
          console.log(value);
          setEPic3(value);

        });
        break;
      default:
        break;
    }
  }
  const addProduct = async () => {
    console.log("Title", title, "Quantity", quantity, "Description", description, "MODEL", model, "SKU", sku, "TYPE", type);
    const product: Product = {
      title: title,
      quantity: quantity,
      description: description,
      model: model,
      sku: sku,
      createdAt: new Date(),
      type: type,
      mainPic: mainPic,
      epic1: ePic1,
      epic2: ePic2,
      epic3: ePic3,
      price: price,
      subCategory:subCategory,
      category:category

    }
    await AddProduct(product).then((value) => {
      console.log("product added",value);
      window.alert("product added");
    })
  }
  return (

    <div style={{ paddingLeft: "15px" }} >
      <Form onSubmit={getFormValues}>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label style={{ color: "black" }}>Title</Form.Label>
          <Form.Control name="title" type="text" onChange={getFormValues} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>type </Form.Label>
          <Form.Control onChange={getFormValues} name="type" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>category </Form.Label>
          <Form.Control onChange={getFormValues} name="category" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>subCategory </Form.Label>
          <Form.Control onChange={getFormValues} name="subCategory" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>sku </Form.Label>
          <Form.Control onChange={getFormValues} name="sku" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Price </Form.Label>
          <Form.Control onChange={getFormValues} name="price" type="number" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Model </Form.Label>
          <Form.Control onChange={getFormValues} name="model" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Description </Form.Label>
          <Form.Control onChange={getFormValues} name="description" type="text" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Quantity </Form.Label>
          <Form.Control onChange={getFormValues} name="quantity" type="number" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Main Picture </Form.Label>
          <Form.Control onChange={getFormValues} name="picture" type="file" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Extra Picture 1 </Form.Label>
          <Form.Control onChange={getFormValues} name="epic1" type="file" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Extra Picture 2 </Form.Label>
          <Form.Control onChange={getFormValues} name="epic2" type="file" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupPassword" >
          <Form.Label style={{ color: "black" }}>Extra Picture 3 </Form.Label>
          <Form.Control onChange={getFormValues} name="picture" type="file" />
        </Form.Group>
        <div style={{ textAlign: "center", margin: "10px" }}>
          <Button variant="primary" type="submit" onClick={addProduct} >
            Add Product
          </Button>
        </div>
      </Form>

    </div>

  )
}


import React from "react";
import Final from "../../assests/videos/Final.mp4"
import Final2 from "../../assests/videos/Final2.ogg"
import "./home.css"
import logo from "../../assests/images/logo.png"
import { Container,Row,Col } from "react-bootstrap";
import 'animate.css';
const Home=()=>{
    
    return(
        <div className="home">
        <div className='overlay' style={{overflow:"hidden"}}>
        <video
          muted
          autoPlay={true}
          loop
          // poster={poster}
          style={{position:"fixed", overflow:"hidden" ,minHeight: "100vh" ,zIndex :"-1"}}
          className={"videoclass"}
          >
            <source  src={Final} type="video/mp4"/>
            <source  src={Final2} type="video/ogg"/>
            
          </video>
  <Container>
  <Row>
  <Col>
    <div className="image-logo">
          <img src={logo} class="vert-move" />
          </div>
          <h1  class="animate__animated animate__bounce  heading">
          We are <span >Net Wave Global </span>
          </h1>
          <h2 class="animate__animated animate__bounceInLeft heading2">Provider IT Solutions & Global Support and Services.</h2>
    </Col> 
  </Row>
  
</Container>
    </div>
</div>
    )
}
export default Home
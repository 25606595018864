import logo from "../assests/images/logo.png"
import { useState, useEffect } from "react";
export const Navigation = (props) => {

const toggleview=()=>{
  const myElement = document.getElementById("bs-example-navbar-collapse-1");
  myElement.style.display ='none';

}
const toggleview2=()=>{
  const myElement = document.getElementById("bs-example-navbar-collapse-1");
  myElement.style.display ='block';

}
  useEffect(() => {
    var myNav = document.getElementById('menu');
    window.onscroll = function () { 
    "use strict";
    if (document.body.scrollTop >= 800 ) {
        myNav.classList.add("nav-colored");
        myNav.classList.remove("nav-transparent");
    } 
    else {
        myNav.classList.add("nav-transparent");
        myNav.classList.remove("nav-colored");
    }
  }
  }, [window]);

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header' >
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            id="tooglebutton"
            onClick={()=>{toggleview2()}}
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
          <a className='navbar-brand page-scroll' href='#page-top'>
          <img src={logo} style={{width:"70px", display:"initial"}}/> Net Wave Global
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li class="hello"onClick={()=>{console.log("I am clickererer");toggleview()}}>
              <a href='/' className='page-scroll'>
                Home
              </a>
            </li>
          <li class="hello"onClick={()=>{console.log("I am clickererer");toggleview()}}>
              <a href='/#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='/#features' className='page-scroll' onClick={()=>{toggleview()}}>
                Technologies
              </a>
            </li>
            <li>
              <a href='/#about' className='page-scroll' onClick={()=>{toggleview()}}>
                About
              </a>
            </li>
            <li>
              <a href='/#contact' className='page-scroll' onClick={()=>{toggleview()}}>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}


import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OverView from "./pages/OverView/OverView";
import HomeViewContainer from "./pages/Home/HomeViewContainer";
import ProductsPage from "./pages/ProductsPage/ProductsPage";
import LoginForm from "./pages/Admin/LoginForm";
import {DashBoard} from "./pages/Admin/Dashboard";
import Checkout from "./pages/Checkout/Checkout";
import Thankyou from "./pages/Thankyou/Thankyou";

const App = () => {

  return (
    <div>
      <BrowserRouter>
        <Routes>
        <Route path="/learnmore" element={<HomeViewContainer/>}>
          </Route>
          <Route path="/" element={<OverView/>}>
          </Route>
          <Route path="/*" element={<OverView/>}>
          </Route>
          <Route path="/products" element={<ProductsPage/>}>
          </Route>
          <Route path="/admin" element={<LoginForm/>}>
          </Route>
          <Route path="/Dashboard" element={<DashBoard/>}>
          </Route>
          <Route path="/Checkout" element={<Checkout/>}>
          </Route>
          <Route path="/Thankyou" element={<Thankyou/>}>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJoC9QGiaCwRbCB0-YpD3k7CK9Ub044cI",
  authDomain: "netwaveglobal-website.firebaseapp.com",
  projectId: "netwaveglobal-website",
  storageBucket: "netwaveglobal-website.appspot.com",
  messagingSenderId: "384122564514",
  appId: "1:384122564514:web:cb47f5a77866a19a220cfb",
  measurementId: "G-6VK41CV73H"
};


// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// export const storage = app.storage();
export const db = getFirestore(app);
export const functions = getFunctions(app);




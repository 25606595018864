import logo from "../assests/images/logo.png"
import { useState, useEffect } from "react";
import {InfoBar} from "./infobar";
export const Headingbar = (props) => {
  console.log("No",props);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const {innerWidth, innerHeight} = window;
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
  
    window.addEventListener('resize', handleWindowResize);
    console.log("hellodude",windowSize);
  
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const toggleview=()=>{
    const myElement = document.getElementById("tooglebutton");
    myElement.setAttribute('aria-expanded', false);
    console.log(myElement);
  }
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  return (
    <div>
    <nav id='menu' className='navbar navbar-default navbar-fixed-top' style={{backgroundColor:"#5f90fd"}}>      
      <div className='container'>
        <div className='navbar-header'>

          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
            id="tooglebutton"
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          
          <a className='navbar-brand page-scroll' href='#page-top'>
          <img  class="vert-move"src={logo} style={{width:"48px", display:"initial" ,marginTop:"0px"}}/> Net Wave Global
          </a>{' '}
          {(innerWidth <770) ? <p onClick={()=>{console.log("Clicked");props.setCartOpen(true)}}>
            <i class="fa fa-shopping-cart" style={{fontSize:"36px"}} aria-hidden="true"></i>
            <span style={{paddingLeft:"3px"}}>{(props?.selectedProducts?.length === 0) ? "": props?.selectedProducts?.length}</span>
            </p>:<></> }
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
          <li class="hello"onClick={()=>{console.log("I am clickererer");toggleview()}}>
              <a href='/' className='page-scroll'>
                Home
              </a>
            </li>
          <li class="hello"onClick={()=>{console.log("I am clickererer");toggleview()}}>
              <a href='/#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='/#features' className='page-scroll'>
                Technologies
              </a>
            </li>
            <li>
              <a href='/#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='/#contact' className='page-scroll' >
                Contact
              </a>
            </li>
            {(innerWidth >770) ? <li onClick={()=>{console.log("Clicked");props.setCartOpen(true)}}>
            <i class="fa fa-shopping-cart" style={{fontSize:"40px"}} aria-hidden="true"></i>
            <span style={{paddingLeft:"3px"}}>{(props?.selectedProducts?.length === 0) ? "": props?.selectedProducts?.length}</span>
            </li>:<></> }
     
          </ul>

        </div>

            

      </div>


    </nav>
             </div>
  )
}

import logo from "../assests/images/logo.png"
import { useState, useEffect } from "react";
import {Navbar,Container,Row} from "react-bootstrap";
;
export const InfoBar = (props) => {


  return (

    <div className="hello" style={{background:"#333",marginTop:'5px'}}>
    <Navbar expand="lg"  bg="dark" variant="dark">
    <Container>
      <Row style={{paddingTop:"10px"}}>
   <span style={{margin:"20px"}}><i class="fa fa-envelope-o" aria-hidden="true"></i>
    Info@netwaveglobal.com
    </span>
    <span style={{margin:"20px"}}><i class="fa fa-phone" aria-hidden="true"></i>{'  '}Tel: +1 (917) 994-0116 
    </span>
    <span style={{margin:"20px"}}><i class="fa fa-map-marker " aria-hidden="true"> </i>    Net Wave Global LLC.
    </span>
    </Row>
     </Container>
</Navbar>
    </div>
  )
}

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {checkout}from "../utils/services/products";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign: "center"
};

export default function ShoppingCart(props) {
  console.log("HI dude", props)
  // const {description,epic1,epic2,epic3,mainPic,model,price,quantity,sku,subType,title,type} =props?.productsDetails;
  const navigate = useNavigate();
  let listItems = []

  const checkoutOutStuff=async ()=>{

    if(props?.selectedProducts?.length>0){
      
      props?.selectedProducts.map( (value)=>{

        listItems.push(
          {
            quantity:value.sQuanity,
            price_data :{
                currency :"USD",
                unit_amount :(value.price)*100,
                product_data :{
                    name:value.title,
                    images: [value.mainPic]
                }
            }
        }
        )
      });

      await checkout(listItems).then((session) =>{
        console.log("SESSIONURL",session);

      });



    }
  }
  return (
    <div style={{ color: "black" }}>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="ShoppingCartModel">
          <h2 style={{ color: "black" }}> Shopping Cart</h2>
          { props?.selectedProducts.length>0 ? 
             (  props?.selectedProducts.map( (value,key)=>{ 
               return( 
          <Card style={{ background: "White", textAlign: "center", color: "black", border: "1px solid black", display: "flex" }}>
              <i onClick={()=>{props.removeproducts(key)}} style={{color:'black',fontSize:"20px"}} class="fa fa-trash" aria-hidden="true"></i>
            <Card.Img style={{ width: '12rem' }} variant="top" src={value.mainPic} />
            <Card.Body>
              <Card.Title className='shoppingCartTitle'>{value.title} </Card.Title>
              <Card.Text className='shoppingCartText'>
                SKU:
                {value.sku}
              </Card.Text>
            </Card.Body>
            <p className='shoppingCartQSR'>Quantity : {value.sQuanity}</p>
            <p className='shoppingCartQSR'>Price <span color="blue">SR {value.price} {key}</span></p>
          
          </Card>
             )}))
                       :(<div></div>) 
         }
          <div style={{marginTop:"10px"}}>

          <Button  onClick={()=>{props.handleClose()}}variant="outlined" color="error" size='large' style={{margin:'5px'}}>
          Continue Shopping
        </Button>
        <Button variant="contained" color="success"size='large' style={{margin:'5px'}}onClick={()=>{localStorage.setItem('cart',JSON.stringify(props)); navigate('/checkout')}}>
          Proceed to Checkout
        </Button>
        </div>
        </Box>

      </Modal>
    </div>
  );
}

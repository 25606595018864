import React from "react";
import { useState, useEffect } from "react";
import Slider from "../../components/Slider";
import { Headingbar } from "../../components/Headingbar";
import Product from "../../components/product";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import {checkout}from "../../utils/services/products";
import Button from '@mui/material/Button';
import { InfoBar } from "../../components/infobar";
import { getAllProducts } from "../../utils/services/products";
import ShoppingCart from "../../components/ShoppingCart";
import { useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import uniqid from 'uniqid';
const Checkout = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [products, setProducts] = useState([]);
  const [props, setprops] = useState();
  const [total,settotal]=useState(0);
  const [nitems,setnitems]=useState(0);
  const [cartItems,setCartItems]=useState([]);
  const [fullName,setFullName]=useState("");
  const [address,setAddress]=useState("");
  const [zipCode,setZipcode]=useState();
  const [phoneNumber,setphoneNumber]=useState();
  const [email,setEmail]=useState('');
  const [cartOpen,setCartOpen]=useState(false);
  const [isError,setIsError]=useState(false);
  const navigate = useNavigate();
  const [selectedProducts,setselectedProducts]=useState([]);
  const productDetails={};


  const checkoutOutStuff=async ()=>{
    console.log("LISTEMSHERE",cartItems);

    if(props?.selectedProducts.length>0){
       if(fullName !=null && phoneNumber !=null && address !=null && zipCode !=null  && email != null){
        setIsError(false);

      const uuidd =uniqid();
      await checkout(cartItems,uuidd).then((session) =>{
        const UserDetails ={
          fullName:fullName,
          contact :phoneNumber,
          address :address,
          zipCode :zipCode,
          email :email,
          productDetails:cartItems,
          sessionId :session.id,
          uuid:uuidd
        }
        localStorage.setItem('OrderDetails',JSON.stringify(UserDetails));
        console.log("SESSIONURL",session);
        window.open( session.url, '_self', 'noopener,noreferrer');

      });
    }
  }
  }

  useEffect(() => {
    setLandingPageData(JsonData);
    getData()

    const props = JSON.parse(localStorage.getItem('cart'));
    let listItems = [];

if(!props){
  
  navigate('/products')
}
setprops(props);
let totalPrice =0;
let totalQuantity =0;
setselectedProducts(props.selectedProducts);
props?.selectedProducts.map( (value)=>{

  listItems.push(
    {
      quantity:value.sQuanity,
      price_data :{
          currency :"USD",
          unit_amount :(value.price)*100,
          product_data :{
              name:value.title,
              images: [value.mainPic]
          }
      }
  }
  
  );
  console.log("HELOCHECKOUT",props,listItems)
  totalPrice= totalPrice + Number(value.price);
  totalQuantity= totalQuantity + value.sQuanity;
  setCartItems(listItems);
});
setselectedProducts(props.selectedProducts);
settotal(totalPrice);
setnitems(totalQuantity);
  }, []);

  const getData = async () => {
    const data = await getAllProducts()
    console.log("helo",data);
    setProducts(data);
  }
  const addProduct=(productDetails)=>{
    selectedProducts.push(productDetails);
    localStorage.setItem("SelectedProducts",selectedProducts);
  }
  const handleClose =()=>{

    setCartOpen(false);
  }
  const openCart =()=>{
    console.log("OPEN THE CARt");
    setCartOpen(true);
  }
  const removefromlist=(key) =>{
  
    let nproducts =selectedProducts;
    nproducts.splice(key,1);
    setselectedProducts(nproducts);
        let totalPrice =0;
    let totalQuantity =0;
    let listItems = [];
    selectedProducts.map( (value)=>{
    
      listItems.push(
        {
          quantity:value.sQuanity,
          price_data :{
              currency :"USD",
              unit_amount :(value.price)*100,
              product_data :{
                  name:value.title,
                  images: [value.mainPic]
              }
          }
      }
      
      );
      console.log("HELOCHECKOUT",props,listItems)
      totalPrice= totalPrice + Number(value.price);
      totalQuantity= totalQuantity + value.sQuanity;
      setCartItems(listItems);
    });
    settotal(totalPrice);
    setnitems(totalQuantity);
    
      }
  const handleInputChange = (event) => {
    const {  value,id } = event.target;

    switch(id) {
      case 'formBasicName':
        // code block
        setFullName(value);
        break;
      case 'formBasicEmail':
        setEmail(value);
        break;
        case 'formBasicZipCode':
          setZipcode(value);
          break;
        case 'formBasicContactNumber':
          setphoneNumber(value);
          break  
        case 'formBasicShippingAddress':
          setAddress(value);
        break
      default:
        // code block
    }
  };
  return (
    <>
      <Headingbar selectedProducts={selectedProducts} setCartOpen={openCart}/>
      <ShoppingCart show={cartOpen}handleClose={handleClose} selectedProducts={selectedProducts}  removeproducts={removefromlist}/>
      
      <div >
        <Slider />
      </div>
      <InfoBar />

      <Container style={{marginTop:'100px',marginBottom:'100px'}}>
      {isError?
                <div> <p style={{color:"red"}}>  There is Something Wrong with your Details,Kindly check again</p></div>: <></>}
      <Row className="CheckoutRow">
        <Col className="CheckoutCol">
        <h2 style={{color:"black"}}> User Details</h2>
        <div>
        <Form>
        <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label style={{color:"black"}}>Full Name</Form.Label>
        <Form.Control type="text" placeholder="Enter Full Name"     onChange={handleInputChange}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label style={{color:"black"}}>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={handleInputChange}/>
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      <Row style={{marginleft:'1px',display:'flex' ,width:'100%'}}>
        <Col style={{width:'30%',paddingRight:"10px" }}>      
      <Form.Group className="mb-3" controlId="formBasicZipCode">
        <Form.Label style={{color:"black"}}>ZipCode</Form.Label>
        <Form.Control type="number" placeholder="Enter Zip Code"    onChange={handleInputChange} />
      </Form.Group>
      </Col>
      <Col>
      <Form.Group className="mb-3" controlId="formBasicContactNumber">
        <Form.Label style={{color:"black"}}>Contact Number</Form.Label>
        <Form.Control type="number" placeholder="Enter Contact Number"     onChange={handleInputChange}/>
      </Form.Group>
      </Col>
      </Row>
      <Form.Group className="mb-3" controlId="formBasicShippingAddress">
        <Form.Label style={{color:"black"}}>Shipping Address</Form.Label>
        <Form.Control type="text" placeholder="Enter Shipping Address"     onChange={handleInputChange}/>
      </Form.Group>

    </Form>
    </div>
        </Col>
        <Col style={{margin:"auto"}}>
        <h2 style={{color:"black"}}> Order Details</h2>
        <h2 style={{color:"#6372ff"}}>
          Number of Items : <span style={{fontFamily:'lato',color:"black"}}>{nitems}</span>
        </h2>
        <h2 style={{color:"#6372ff"}}>
          Total  :<span style={{fontFamily:'lato',color:"black" }}> USD {total}</span>
        </h2>
        <div style={{width:'100%',textAlign:"center"}}>
          <span style={{margin:"auto"}}>
        <Button  style={{width:"224px",height:"70px",fontSize:"19",margin:"auto",textAlign:"center"}} variant="contained" color="success"size='large' onClick={()=>{checkoutOutStuff();}}>
          Checkout & Pay
        </Button>
        </span>
        </div>
       </Col>
      </Row>

    </Container>



      <Contact data={landingPageData.Contact} />
    </>
  )
}
export default Checkout
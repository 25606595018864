// import { ContactsOutlined } from "@material-ui/icons";
// import { Checkout } from 'checkout-sdk-node';
// const cko = new Checkout('sk_test_924393fc-8f98-4577-9291-a4a545e944d3');
import Stripe from 'stripe';

const stripe = new Stripe('sk_test_51KVZr8ByJKB7kZpdMsMs3AoMcdEfSpLQkEG7xkewnUJJtlxiyR1PYIsMKnejjGQWmBIWIGDjfvkES5im7zn9iB5C00yqIrLiim', {
  apiVersion: '2020-08-27',
  typescript:true
});
// async function postData(url = 'https://api.sandbox.checkout.com/hosted-payments', data = {}) {
//     // Default options are marked with *
//     const response = await fetch(url, {
//       method: 'POST', // *GET, POST, PUT, DELETE, etc.
//       //mode: 'cors', // no-cors, *cors, same-origin
//       //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//      // credentials: 'same-origin', // include, *same-origin, omit
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization':'pk_test_1eaf3e4f-043e-4e36-9a1d-e8843c741665'
//       },
//    //   redirect: 'follow', // manual, *follow, error
//      // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//       body: JSON.stringify(data) // body data type must match "Content-Type" header
//     });
//     return response.json(); // parses JSON response into native JavaScript objects
//   }
  
  export const getdata=async()=>{
    console.log("HELLO G DUDE")
      const session = await stripe.checkout.sessions.create({
        line_items: [
          {
            price_data: {
              currency: 'usd',
              product_data: {
                name: 'T-shirt',
              },
              unit_amount: 2000,
            },
            quantity: 1,
          },
        ],
        mode: 'payment',
        success_url: 'https://example.com/success',
        cancel_url: 'https://example.com/cancel',
      });
      
      console.log("session",session)
      console.log("Stripe",stripe);

}
import React from "react"
import SimpleImageSlider from "react-simple-image-slider";
const Slider =()=>{

    const images = [
        { url: "https://media.istockphoto.com/photos/network-cables-connected-to-switch-picture-id505921926?b=1&k=20&m=505921926&s=170667a&w=0&h=5_ov95klaDSxeSdAWxZXi3lcR2u65TdYZjI8eZARSBE=" },
        { url: "https://wallpaperaccess.com/full/302537.jpg" },
        { url: "https://wallpapercave.com/wp/wp5599755.jpg" },
      ];
return (
<div className="banner">
<div className="slider">
<div class="container Big">
<SimpleImageSlider  style={{display:"block",maxHeight:"600px",position:"relative" ,overflow:"hidden"}}width={"100%"} height={"400px"} images={images} showBullets={true} showNavs={true} autoPlay={true} loop={true}/>

</div>
</div>
</div>
)
}
export default Slider
import React from "react";
import { useState, useEffect } from "react";
import Slider from "../../components/Slider";
import { Headingbar } from "../../components/Headingbar";
import Product from "../../components/product";
import { Contact } from "../../components/contact";
import JsonData from "../../data/data.json";
import { Button, ButtonGroup,Dropdown ,DropdownButton  } from "react-bootstrap";
import { InfoBar } from "../../components/infobar";
import { getAllProducts,getAllProductswithLimit,gettypeWiseProduct,getAllProductswith,gettypeWiseProductWithCategory } from "../../utils/services/products";
import "./productsPage.css"
import ShoppingCart from "../../components/ShoppingCart";
import {getdata} from "../../service";
const ProductsPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [products, setProducts] = useState([]);
  const [lastVisible, setlasvisible] = useState();
  const [cartOpen,setCartOpen]=useState(false);
  const [cartProducts, setCartProducts] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [ProductsTypeHeading, setProductsTypeHeading] = useState('All Products');
  let selectedProducts=[];
  const productDetails={};
  useEffect(() => {
    console.log("HELLODUDE")
    getdata();
    setLandingPageData(JsonData);
    getData()

    const props = JSON.parse(localStorage.getItem('cart'));
    {
      if(props){
        setCartProducts(props.selectedProducts);
      }
    }
  }, []);
  useEffect(() => {
    console.log("products",products);
  }, [products]);

  // const getData = async () => {
  //   // await getAllProducts().then((data)=>{
  //   //   console.log("helo",data);
  //   //   setProducts(data);
  //   // })

  // }
  const getData = async () => {
    if(!isloading){
      setIsloading(true);
    await getAllProductswithLimit().then((data)=>{
      console.log("helo",data);
      setProducts(data.data);
      setlasvisible(data.lastVisible);
      setIsloading(false);
    })
  }

  }
  const removefromlist=(key) =>{
  
let nproducts =cartProducts;
console.log("Cart products",nproducts);
nproducts.splice(key,1);
setCartProducts(nproducts);
console.log("product removed",nproducts);
setCartOpen(false);

  }
  const getDataWithLimit = async () => {
    if(!isloading){
      setIsloading(true);
    await getAllProductswith(lastVisible).then((data)=>{
      console.log("helo",data);
      const nproducts =[...products,...data.data];
      console.log(nproducts);
      setProducts(nproducts);
      setlasvisible(data.lastVisible);
      setIsloading(false);
    })
  }
  }
  const getTypeData = async (type) => {
    console.log("I am Called");
    if(!isloading){
      setIsloading(true);
    await gettypeWiseProduct(type).then((data)=>{
      console.log("helo",data);
      setProducts(data);
      setProductsTypeHeading(type);
      setIsloading(false);
    })
  }
  }
  const getTypeDataWithCategory = async (type,category) => {
    if(!isloading){
      setIsloading(true);
    await gettypeWiseProductWithCategory(type,category).then((data)=>{
      console.log("helo",data);
      setProducts(data);
      setProductsTypeHeading(type+ '/' + category);
      setIsloading(false);
    })
  }
  }
  const addProduct=(productDetails)=>{
    selectedProducts.push(productDetails);
    localStorage.setItem("SelectedProducts",selectedProducts);
    console.log("cart",selectedProducts)
    setCartProducts([...cartProducts,productDetails]);
  }
  const handleClose =()=>{
    setCartOpen(false);
  }
  const openCart =()=>{
    console.log("OPEN THE CARt");
    setCartOpen(true);
  }
  return (
    <>
      <Headingbar selectedProducts={cartProducts} setCartOpen={openCart}/>
      <ShoppingCart show={cartOpen}handleClose={handleClose} selectedProducts={cartProducts} removeproducts={removefromlist}/>

      <div >
        <Slider />
      </div>
      <InfoBar />

      <div style={{ margin: "10px", textAlign: "center" }}>
        <h2 style={{ color: "#333", fontFamily: "Raleway, sans-serif" }}>Main Categories</h2>

<div style={{display:"flex"}}>
  <div className="CategoryButtons">
      <DropdownButton size="lg"  split  variant='info'id="dropdown-basic-button" className="MainCategoryButtons" title="COPPER SOLUTION" >
      <div>
      <Dropdown.Item onClick={()=>{getTypeData("Copper Solution")}}>All Copper Solutions</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Copper Solution","CommScope Copper Solution")}}>CommScope Copper Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Copper Solution","Corning Copper Solution")}}> Corning Copper Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item  onClick={()=>{getTypeDataWithCategory("Copper Solution","Finosel Copper Solution")}}>Finosel Copper Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item  onClick={()=>{getTypeDataWithCategory("Copper Solution","Nexans Copper Solution")}}>Nexans Copper Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item  onClick={()=>{getTypeDataWithCategory("Copper Solution","LS Copper Solution")}}>LS Copper Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item  onClick={()=>{getTypeDataWithCategory("Copper Solution","Other Copper Solution")}}> Other Copper Solution</Dropdown.Item>
      </div>
    </DropdownButton>

    <DropdownButton size="lg" className="MainCategoryButtons"  variant='info'id="dropdown-basic-button" title="FIBER OPTICAL SOLUTIONS">
      <div>
      <Dropdown.Item  onClick={()=>{getTypeData("Fiber Optic Solution")}}>All Fiber Optic Solution</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item  onClick={()=>{getTypeDataWithCategory("Fiber Optic Solution","CommScope Fiber Solution")}}>CommScope Fiber Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Fiber Optic Solution","Corning Fiber Solution")}}> Corning Fiber Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Fiber Optic Solution","Finosel Fiber Solution")}}>Finosel Fiber Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Fiber Optic Solution","Nexans Fiber Solution")}}>Nexans Fiber Solution</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Fiber Optic Solution","LS Fiber Solution")}}>LS Fiber Solution</Dropdown.Item>
      </div>

    </DropdownButton>
    <DropdownButton size="lg" className="MainCategoryButtons"  variant='info'id="dropdown-basic-button" title="RACKS & CABINETS">
      <div>
      <Dropdown.Item onClick={()=>{getTypeData("Racks and Cabinets")}}>All Racks and Cabinets</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Racks and Cabinets","Finosel Rack & PDUs")}}>Finosel Rack & PDUs</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Racks and Cabinets","APC Rack & PDUs")}}> APC Rack & PDUs</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Racks and Cabinets","Other Rack & PDU’s")}}>Other Rack & PDU’s</Dropdown.Item>
      </div>


    </DropdownButton>
    <DropdownButton size="lg" className="MainCategoryButtons"  variant='info'id="dropdown-basic-button" title="SWITCHES" >
    <div>
      <Dropdown.Item onClick={()=>{getTypeData("Switches")}}>All Switches</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Switches","Cisco Switches")}}>Cisco Switches</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Switches","Aruba switch & AP's")}}> Aruba switch & AP's</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Switches","Planet switches")}}>Planet switches</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Switches","Other switches")}}>Other switches</Dropdown.Item>
      </div>
    </DropdownButton>
    <DropdownButton size="lg" className="MainCategoryButtons"  variant='info'id="dropdown-basic-button" title="UPS" >
    <div>
      <Dropdown.Item onClick={()=>{getTypeData("All Ups")}}>All UPS</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Ups","APC UPS")}}>APC UPS</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Ups","Tripp Lite UPS")}}> Tripp Lite UPS</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Ups","Other Ups")}}>Other Ups</Dropdown.Item>
      </div>
    </DropdownButton>
    <DropdownButton size="lg" className="MainCategoryButtons"  variant='info'id="dropdown-basic-button" title="TOOLS" >
    <div>
      <Dropdown.Item onClick={()=>{getTypeData("Tools")}}>All Tools</Dropdown.Item>
      </div>
        <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Tools","Fiber Tools")}}>Fiber Tools</Dropdown.Item>
      </div>
      <div>
      <Dropdown.Item onClick={()=>{getTypeDataWithCategory("Tools","Copper Tools")}}> Copper Tools</Dropdown.Item>
      </div>

    </DropdownButton>
    </div>
    </div>

        {/* <ButtonGroup size="lg" className="mb-2">
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Copper Solution")}}>COPPER SOLUTIONS</Button>
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Fiber Optic Solution")}}>FIBER OPTIC SOLUTIONS</Button>
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Racks and Cabinets")}}>RACKS & CABINETS</Button>
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Switches")}}>SWITCHES</Button>
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Ups")}}>UPS</Button>
          <Button variant="info"  className="MainCategoryButtons" onClick={()=>{getTypeData("Tools")}}>TOOLS</Button>
        </ButtonGroup> */}

      </div>
      <div className="container ">
        <h2 style={{ color: "grey", textAlign: "center" }}>{ProductsTypeHeading}</h2>
        <hr></hr>
        <div className="container ">
          <div>
            <div className="row">
              { products.length>0 ? 
             (  products.map( (value)=>{ 
               return( 
               <div className='col-md-3' style={{ margin: "auto", padding: "1% 1% 1% 3px", width: "310px" }}>
                <Product productsDetails={value}  addProduct={addProduct}/>
              </div>
               )
             }))
              :(<div> <h2 style={{ color: "red", textAlign: "center" }}>"Sorry no products are available in this category".</h2></div>) 
}

            </div>
          </div>
        </div>
        <div style={{width:'100%', textAlign:"center",marginTop:"15px",marginBottom:"15px"}}>
        <Button variant="primary"  className="MainCategoryButtons" onClick={()=>{getDataWithLimit()}}>Show More</Button>
      
        </div>
      </div>
      <Contact data={landingPageData.Contact} />
    </>
  )
}
export default ProductsPage
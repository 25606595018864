
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {app,db,functions} from "../firebase/firebase";
import { addDoc, collection , query, where, getDocs,startAfter, limit} from "firebase/firestore"; 
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import Stripe from "stripe";
export const uploadfile =async(file,name)=> {
const storage = getStorage(app);
const storageRef = ref(storage,name);

// 'file' comes from the Blob or File API
 const snapshot =  await uploadBytes(storageRef, file).then((snapshot) => {
     return snapshot;
});
   
 const url=  await getDownloadURL(ref(storage,snapshot.metadata.fullPath))
.then((url) => {
  // `url` is the download URL for 'images/stars.jpg'
  console.log(url);
  return url;
})
return url;

}
export const AddProduct = async (product) => {
    try {
        const docRef = await addDoc(collection(db, "products"), product);
      
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
}
export const PlaceOrder = async (orderDetails) => {
  try {
      const docRef = await addDoc(collection(db, "orders"), orderDetails);
    
      console.log("Document written with ID: ", docRef.id);
      return docRef.id;
    } catch (e) {
      console.error("Error adding document: ", e);
    }
}
export const getAllProducts = async () => {
  try {
    const data=[];
    const q =  await query(collection(db, "products"), where("quantity", ">", "0"));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    return data;
    } catch (e) {
      console.error("Error having document: ", e);
      return [];
    }

}
export const getAllProductswith = async (lastVisible) =>{
  try {
  const data =[];
  const next = query(collection(db, "products"),
    where("quantity", ">", "0"),
    startAfter(lastVisible),
    limit(10));
    console.log("next",next);
    const querySnapshot = await getDocs(next);
    const lastVisibles =  await querySnapshot.docs[querySnapshot.docs.length-1];
    console.log("lastVisibles",lastVisibles);
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    return {data:data,lastVisible:lastVisibles};
  }catch(e){
    console.error("Error having document: ", e);
      return [];
  }

}
export const getAllProductswithLimit = async () => {
  try {
    const data=[];

    const first = await query(collection(db, "products"),where("quantity", ">", "0"), limit(10));
    console.log("FIrst",first);
    const documentSnapshots = await getDocs(first);
    console.log("documentSnapshots",documentSnapshots);
    const lastVisible = await documentSnapshots.docs[documentSnapshots.docs.length-1];
    console.log("lastVisible",lastVisible);
    await documentSnapshots.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    return {data:data,lastVisible:lastVisible};
    } catch (e) {
      console.error("Error having document: ", e);
      return [];
    }

}

export const gettypeWiseProduct = async ( type) => {
  try {
    const data=[];
    const q =  await query(collection(db, "products"), where("type", "==", type));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    return data;
    } catch (e) {
      console.error("Error having document: ", e);
      return [];
    }

}
export const gettypeWiseProductWithCategory = async ( type,category) => {
  try {
    const data=[];
    const q =  await query(collection(db, "products"), where("type", "==", type), where("category", "==", category));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log("HELLO IBAAD")
      data.push(doc.data());
    });
    return data;
    } catch (e) {
      console.error("Error having document: ", e);
      return [];
    }

}
export const gettypeWiseProductWithCategoryandSubcategory = async ( type,category,subCategory) => {
  try {
    const data=[];
    const q =  await query(collection(db, "products"), where("type", "==", type), where("category", "==", category), where("subCategory", "==", subCategory));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      data.push(doc.data());
    });
    return data;
    } catch (e) {
      console.error("Error having document: ", e);
      return [];
    }

}
export const checkout =async (listItems,uuid)=>{

  const createStripeCheckout =httpsCallable(functions,'createStripeCheckout');
  const stripe = Stripe('pk_live_51LDmmUR10yXdTnE2HdRHhTDvPJAl7NWkw8EZgIHdHxUJDdeb5HtguPNYg6bvdiG0wJ8f0sN39s5M6pBOXBTTAcpY00mbTi1zG6');
 const data =await  createStripeCheckout({listItems:listItems,uuid:uuid}).then(response =>{
    const sessionId =response.data.id;
    console.log("HERE IS THE SESSION ID",response);
    // stripe.redirectToCheckout({sessionId:sessionId});
    
    if( response.data.session){
       
      return response.data.session;
     }
     else{
      return null;
     }
   
  })

  return data;
}

import React from "react";
import Home from "./Home"
import We from "./we";
import { Navigation } from "../../components/navigation";
const HomeViewContainer =()=>{

    return(
    <>
    <div>
    <Navigation />
    <Home/>
    <We/>
    </div>
    </>
    )
}
export default HomeViewContainer
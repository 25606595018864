import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import {Card,Button,Form} from "react-bootstrap"
import "./LoginForm.css";
import {SignIn,verifyToken} from "../../utils/services/admin"

const LoginForm = () => {
    const navigate = useNavigate();
const [email,setEmail]=useState<any>();
const [password,setPassword]=useState<any>();

const getFormValues =(event:any)=>{
    event.preventDefault();
    console.log(event.target.name,event.target.value)
    if(event.target.name === "email" )
    {
        setEmail(event.target.value)
    }
    if(event.target.name === "password" )
    {
        setPassword(event.target.value)
    }
}
const  login =async ()=>{
        console.log("SendingData",email,password)
      let user :any = await SignIn(email,password);
      const token = localStorage.getItem("token");
      console.log("Token",token,user);
    //  let verified= await verifyToken(token);
      //console.log("verigied",verified)
        if(token)
        {
            navigate('/dashboard');
        }
}
return (
<div className="loginForm" style={{textAlign:"center",margin:"auto"}}>
<Card className="logincard">
  <Card.Header as="h5">Login</Card.Header>
  <Card.Body>
  <Form onSubmit={getFormValues}>
  <Form.Group className="mb-3" controlId="formGroupEmail">
    <Form.Label>Email address</Form.Label>
    <Form.Control name="email"type="email" placeholder="Enter email" onChange={getFormValues}/>
  </Form.Group>
  <Form.Group className="mb-3" controlId="formGroupPassword" >
    <Form.Label>Password</Form.Label>
    <Form.Control onChange={getFormValues} name="password" type="password" placeholder="Password" />
  </Form.Group>
  <Button variant="primary" type="submit" onClick={login}>
    Login
  </Button>
</Form>
  </Card.Body>
</Card>
    </div>
)
}

export default LoginForm;